<template>
    <div class="mainRoot" :style="cssProps">
        <v-app-bar flat class="mainListHeader" style="z-index: 16">
            <v-icon v-if="!selectedCategory" color="accent" size="45">$vuetify.icons.logo</v-icon>
            <v-app-bar-nav-icon
                v-if="selectedCategory"
                @click="selectedCategory = null"
                :style="cssProps"
            >
                <v-icon class="backButton ms-n1" color="black">$vuetify.icons.back</v-icon>
            </v-app-bar-nav-icon>
        </v-app-bar>

        <div class="mainItemList" v-on:scroll="onHeaderScroll" ref="mainItemList">
            <div class="audioItemList">
                <!-- NO LIVE AUDIO CONTAINER -->
                <div v-if="showNoLive && (!audioFeedItems || audioFeedItems.length === 0)">
                    <v-container
                        class="text-center overlayControls"
                        style="pointer-events: none;height: var(--v-audio-player-height);overflow: hidden;"
                        fluids
                        fill-height
                    >
                        <v-row align="center" justify="center">
                            <v-col>
                                <div class="storyText" style="color:black">
                                    {{ $t("no_live_show") }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div v-else class="radioListBottomPart">
                    <div v-if="liveBroadcastOn">
                        <!-- Live item -->
                        <AudioItemLive
                            :ref="liveItem.guid"
                            :disabled="!liveBroadcastOn"
                            class="ma-0 audioListRadioLiveItem"
                            :item="liveItem"
                            :isSelected="liveItem == $root.mediaPlayerItem"
                            v-on:itemClicked="playItemFromMediaList($event)"
                            v-on:playItem="playItemFromMediaList($event)"
                        />
                        <!-- Divider, if live item shown -->
                        <div class="listDivider" />
                    </div>
                    <!-- Audio List display if there is only 1 podcast otheriwise show grid -->
                    <ItemListGrid
                        v-if="!selectedCategory && uniqueCategories.length > 1"
                        :uniqueCategories="uniqueCategories"
                        :index="index"
                        @click="itemClicked(categoryObj, index)"
                        @selectedCategory="categorySelected"
                    />

                    <!--pod grid -->
                    <ItemListAudio
                        v-if="selectedCategory || uniqueCategories.length === 1"
                        :items="selectedCategory ? selectedCategory : audioFeedItems"
                        v-on:playItem="playItemFromMediaList($event)"
                        v-on:itemClicked="playItemFromMediaList($event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Home from "./Home";
    import ItemListAudio from "../components/ItemListAudio";
    import ItemModel from "../models/itemmodel";
    import AudioItemLive from "../components/AudioItemLive";
    import ItemListGrid from "../components/ItemListGrid.vue";

    export default {
        name: "Radio",
        extends: Home,
        components: {
            ItemListAudio,
            AudioItemLive,
            ItemListGrid,
        },
        props: {
            index: {
                type: Number,
                default: 0,
            },
        },
        data: () => ({
            liveItem: new ItemModel(),
            selectedCategory: null,
            items: [],
        }),
        methods: {
            categorySelected(categoryObj) {
                this.selectedCategory = categoryObj.items;
                const firstPod = categoryObj.items[0];
                this.$store.commit("setCurrentPodcast", firstPod);
            },
            playLiveRadio() {
                if (this.liveItem.enclosure == null || this.liveItem.enclosure == "") {
                    console.log("Radio: no url!");
                    return;
                }
                console.log("Play radio from: " + this.liveItem.enclosure);
                this.playItemFromMediaList({
                    item: this.liveItem,
                });
                this.$root.mediaPlayerDocked = true;
            },
        },

        destroyed() {
            if (this.storeWatchObject != null) {
                console.log("Radio destroyed, stop listening");
                this.storeWatchObject();
                this.storeWatchObject = null;
            }
        },

        mounted() {
            this.liveItem = new ItemModel();
            this.liveItem.guid = "LiveItemGuid";
            this.liveItem.isLive = true;
            this.liveItem.title = this.$t("live_radio_show");
            this.liveItem.enclosureType = "audio/mpeg";
            this.liveItem.autoplay = true;
            if (this.$store.state.liveRadio != null) {
                this.liveItem.enclosure = this.$store.state.liveRadio.url;
                this.liveItem.enclosureDuration = this.$store.state.liveRadio.duration;
            }

            this.storeWatchObject = this.$store.watch(
                (state) => state.liveRadio,
                // eslint-disable-next-line
                (newValue, oldValue) => {
                    if (oldValue != null && newValue != null && oldValue.url == newValue.url) {
                        return; // No change
                    }
                    console.log("Live radio url updated from " + oldValue + " to " + newValue);
                    if (newValue != null) {
                        this.liveItem.enclosure = newValue.url;
                        this.liveItem.enclosureDuration = newValue.duration;
                    } else {
                        this.liveItem.enclosure = null;
                        this.liveItem.enclosureDuration = 0;
                    }
                    if (newValue == null) {
                        // Stop radio
                        console.log("Stop radio");
                        this.$root.clearMediaItem();
                    } else {
                        this.playLiveRadio();
                    }
                }
            );
        },

        computed: {
            uniqueCategories() {
                const uniqueCategories = []; /* filter audio feed for individual podcasts */
                this.audioFeedItems.forEach((item) => {
                    const categoryIndex = uniqueCategories.findIndex(
                        (category) => category.category === item.category
                    );
                    if (categoryIndex === -1) {
                        uniqueCategories.push({
                            category: item.category,
                            imageUrl: item.imageSrc,
                            items: [item],
                        });
                    } else {
                        uniqueCategories[categoryIndex].items.push(item);
                    }
                });
                return uniqueCategories;
            },
            showNoLive() {
                return (
                    (this.$root.mediaPlayerItem == null ||
                        (this.$root.mediaPlayerItem && this.$root.mediaPlayerItem.isLive)) &&
                    !this.liveBroadcastOn
                );
            },
            audioFeedItems() {
            if (this.$store.state.currentFeedAudio != null && this.$store.state.currentFeedAudio.type != 'rivendell') {
                let mergedItems = [];
                this.$store.state.currentFeedAudio.forEach(({ feed, items }) => {
                        const audioItems = items;
                        const feedDescription = feed.description
                        audioItems.forEach((item) => {
                            item.channelDescription = feedDescription;
                            item.category = feed.title;
                            item.imageSrc = feed.imageUrl;
                        });
                        mergedItems.push(...audioItems);
                    });
                return mergedItems;
            }
            else if (this.$store.state.currentFeedAudio != null ){
                return this.$store.state.currentFeedAudio.items;
            }
            else {
              return  console.warn("no items found")
            }
            },
            liveBroadcastOn() {
                return (
                    this.liveItem != null &&
                    this.liveItem.enclosure != null &&
                    this.liveItem.enclosure != ""
                );
            },
            playerObject() {
                if (this.$root.mediaPlayer != null) {
                    console.log("Return shared player");
                    return this.$root.mediaPlayer;
                }
                return null;
            },
        },

        watch: {
            "$root.mediaPlayerItem"(item) {
                if (item) {
                    const foundItem = this.audioFeedItems.find((i) => {
                        return i.guid == item.guid;
                    });
                    if (this.$route.name == this.thisRouteName) {
                        this.$root.mediaPlayerDocked = foundItem;
                    }
                }
            },
            $route: function(to, ignoredFrom) {
                if (this.thisRouteName == to.name) {
                    console.log("Radio Shown");
                }
            },
        },
    };
</script>

<style scoped>
    @import url("../assets/css/item-style.css");
    @import url("../assets/css/shared-styles.css");

    .radioListBottomPart {
        position: absolute;
        top: 56px;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 58px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .audioListRadioList {
        flex-grow: 1;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .audioListRadioLiveItem {
        flex-grow: 0;
        border-top: 1px solid #d8d8d8;
    }

    .overlayControls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
