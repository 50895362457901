<template>
    <div class="ma-0 my-2 pa-0" ref="container">
        <v-card v-if="this.currentPodcast" class="mx-auto" tile elevation="0">
            <v-container ma-0 pa-0>
                <v-row class="d-flex  justify-center pa-3" style="max-width:100%">
                    <v-expand-x-transition>
                        <v-col class="ease" :cols="isOpen ? 3 : 5" size="125">
                            <v-img transition="resize-transition " class="elevation-3 rounded-lg "
                                :src="this.currentPodcast.imageSrc|| this.currentPodcast.cover"></v-img>
                        </v-col>
                    </v-expand-x-transition>

                    <v-col class="ps-2 ease">
                        <div class="pb-2 text-h5 font-weight-bold" v-text="this.currentPodcast.category || this.currentPodcast.title">
                        </div>
                        <VueReadMoreSmooth  v-model="isOpen" @update:open="handleOpenChange" :lines="3" :max-lines="3" class="pb-3">
                            <div :key="descriptionKey" class="text-subtitle-2"
                                style="font-weight: 200;font-size: calc(.8 * var(--v-theme-font-size));line-height: var( --v-theme-description-featured-line-height);word-break: break-word;" v-html="itemDescription">
                            </div>
                            <template v-slot:more="value">
                                <div class="pt-1 black--text text-button noscale"
                                    style="color: var(--v-accent-lighten1); font-weight: 700; font-size: calc(.7 * var(--v-theme-font-size));  font-family:var(--v-theme-font);">
                                    {{ value.open ? $t("show_less") : $t("show_more") }}
                                </div>
                            </template>
                        </VueReadMoreSmooth>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-list ref="list" class="itemList mt-2">
            <div
                v-for="(item, index) in items"
                :key="item.guid"
                style="background-color: var(--v-audioCardBackground-base)"
            >
                <v-lazy ma-0 pa-0 transition="fade-transition" min-height="60px">
                    <AudioItem
                        :ref="item.guid"
                        class="ma-0"
                        :item="item"
                        :isSelected="
                            item.guid == $store.state.mediaPlayerItem.guid &&
                            $store.state.isAudioPlaying
                        "
                        v-on:itemClicked="playItem($event)"
                        v-on:playItem="playItem($event)"
                    />
                </v-lazy>
                <!-- If not last item, add a divider between items -->
                <div ma-0 pa-0 v-if="index < items.length - 1" class="listDivider  mx-3" />
            </div>
        </v-list>
    </div>
</template>

<script>
import ItemListBase from "./ItemListBase";
import AudioItem from "./AudioItem";

import VueReadMoreSmooth from "./ReadMore.vue";



export default {
    extends: ItemListBase,
    components: {
        AudioItem,
        VueReadMoreSmooth
    },
    data: () => {
        return {
            isOpen: false,
            descriptionKey: 0,
        };
    },

    computed: {
        currentPodcast() {
            if (this.$store.state.currentPodcast) {
                return this.$store.state.currentPodcast;
            } else {
                    return this.items[0];
            }
        },

        itemDescription() {
            return this.truncDescription(this.currentPodcast?.channelDescription || this.currentPodcast?.description);
        },

    },
    methods: {
        truncDescription(text) {
            if (!text) return "";
            const brIndex = text.indexOf('<br');
            return brIndex !== -1 ? text.substring(0, brIndex).trim() : text.trim();
        },
        handleOpenChange(newValue) {
            this.isOpen = newValue;
        },
        getKey(item) {
            return this.$root.acast ? item._id : item.guid;
        },
    }
};
</script>
<style>
.v-sheet.v-card {
    border-radius: 0;
}

.ease {
    transition: all 0.3s ease-out;
}

</style>
